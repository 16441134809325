import React, { useEffect, useState } from "react";
import "./Payment.scss";
import ImgTik from "../../assets/tokmel.png";

const Payment = () => {

  const [relocate, setReloacte] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      setReloacte(window.innerWidth <= 900);
    };

    // Initial check
    handleResize();

    // Event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="payment">
      <h2>ЗАРПЛАТЫ</h2>

      {!relocate ? <>
        <div className="box center">
        <div className="text-box ">
          <p>
            Начиная с 21.10.2023 все свежие нарезки с Меллстроем (дата
            выложенного видео), набравшие 100.000, 500.000, 1.000.000 просмотров
            и более, будут получать в два раза больше за видео с Меллстроем:
          </p>
          <p>👀 100.000-400.000 просмотров - 20-80$</p>
          <p>👀 500.000-900.000 просмотров - 100-180$</p>
          <p>👀 1.000.000 просмотров - 200$ и более...</p>
        </div>
        <div className="img">
          <img src={ImgTik} alt="" />
        </div>
        <div className="empty"></div>
      </div>
      </> : <>
      
      <div className="box center">
        <div className="text-box ">
          <p>
            Начиная с 21.10.2023 все свежие нарезки с Меллстроем (дата
            выложенного видео), набравшие 100.000, 500.000, 1.000.000 просмотров
            и более, будут получать в два раза больше за видео с Меллстроем:
          </p>
          <p>👀 100.000-400.000 просмотров - 20-80$</p>
          <p>👀 500.000-900.000 просмотров - 100-180$</p>
          <p>👀 1.000.000 просмотров - 200$ и более...</p>
          <img src={ImgTik} alt="" />
        </div>

      </div></>}
      {/* <div className="box center">
        <div className="text-box ">
          <p>
            Начиная с 21.10.2023 все свежие нарезки с Меллстроем (дата
            выложенного видео), набравшие 100.000, 500.000, 1.000.000 просмотров
            и более, будут получать в два раза больше за видео с Меллстроем:
          </p>
          <p>👀 100.000-400.000 просмотров - 20-80$</p>
          <p>👀 500.000-900.000 просмотров - 100-180$</p>
          <p>👀 1.000.000 просмотров - 200$ и более...</p>
        </div>
        <div className="img">
          <img src={ImgTik} alt="" />
        </div>
        <div className="empty"></div>
      </div> */}
    </div>
  );
};

export default Payment;
