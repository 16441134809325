import React from "react";
import Cards from "../components/cards/Cards";
import Tiktok from "../components/tiktok/Tiktok";
import More from "../components/more/More";
import Payment from "../components/payment/Payment";

const HomePage = () => {
  return (
    <>
      <Cards text={"ГДЕ ИГРАЕТ"} mel={"МЕЛЛСТРОЙ"} />
      <Tiktok />
      <More />
      <Payment />
      <Cards text={"КАЗИНО ГДЕ ИГРАЕТ"} mel={"МЕЛЛСТРОЙ"} />
    </>
  );
};

export default HomePage;
