import React, { useEffect, useState } from "react";
import "./Header.scss";
import logo from "../../assets/logo.png"
const Header = () => {
	const [menuOpen, setMenuOpen] = useState(false);
	const [showLogo, setShowLogo] = useState(true);

	useEffect(() => {
		const handleResize = () => {
		setShowLogo(window.innerWidth <= 768);
			};

			// Initial check
			handleResize();

			// Event listener for window resize
			window.addEventListener('resize', handleResize);

			// Cleanup the event listener on component unmount
			return () => {
		window.removeEventListener('resize', handleResize);
			};
	}, []);

	return (
		<header>
			<div className="header-container center">
				
			<nav>
			{showLogo ? (<a href="/">
				<img src={logo} alt="logo" />
			</a> ) : <></>}
				<div className="menu" onClick={() => setMenuOpen(!menuOpen)}>
					<span></span>
					<span></span>
					<span></span>
				</div>
				
				<ul className={menuOpen ? "open" : ""}>
					<li>
						<a href="/">ГЛАВНАЯ</a>
					</li>
					<li>
						<a href="https://docs.google.com/spreadsheets/d/1YxfQveUZxhKvGywrvUscaFEhwaX5jH-ukXKT0H4zkpo/edit?usp=sharing" target="_blank" rel="noreferrer">ВЫПЛАТЫ</a>
					</li>
					<li>
						<a href="/streams">СТРИМЫ</a>
					</li>
				</ul>
				</nav>

			</div>
		</header>
	);
};

export default Header;
