import React from "react";
import "./Stream.scss";

const Stream = () => {
  return (
    <div className="stream">
      <div className="stream-cont center">
        <iframe
          title="Melstroy"
          src="https://player.kick.com/mellstroy"
          height="720"
          width="1200"
          frameborder="0"
          scrolling="no"
          allowfullscreen="true"
          border="0"
        ></iframe>
      </div>
    </div>
  );
};

export default Stream;
