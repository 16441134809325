import "./App.css";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import StreamsPage from "./pages/StreamsPage";

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    setIsVisible(scrollTop > 100);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <button
      className={`scroll-to-top ${isVisible ? "visible" : "hidden"}`}
      onClick={scrollToTop}
    >
      <i class="fa-solid fa-arrow-up fa-2xl" style={{ color: "#ffffff" }}></i>
    </button>
  );
};

function App() {
  return (
    <>
      <Header />
      <div className="app">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/streams" element={<StreamsPage />} />
          </Routes>
        </BrowserRouter>
      </div>

      <Footer />
      <ScrollToTopButton />
    </>
  );
}

export default App;
