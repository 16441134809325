import React from "react";
import "./Cards.scss";
import kent from "../../assets/kent.png";
import star from "../../assets/star.svg";
import vavada from "../../assets/vavada.png";
import gamma from "../../assets/gamma.png";
import win1 from "../../assets/win1.png";
import cat from "../../assets/cat.png";
import daddy from "../../assets/daddy.png";
import pinup from "../../assets/pinup.png";
import izzi from "../../assets/izzi.png";

const Cards = ({ text, mel }) => {
  const data = [
    {
      logo: kent,
      bonus: "150FS + 325% БОНУС",
      link: "https://passage-through-trees.com/d753a0bcb",
    },
    {
      logo: gamma,
      bonus: "500% БОНУС",
      link: "https://colorful-road-three.com/d35db545f",
    },
    {
      logo: vavada,
      bonus: "200FS + 395% БОНУС",
      link: "https://vavadapartners.pro/?promo=9e1e1391-392b-4e33-87c7-a3523df5e8cf&target=register",
    },
    {
      logo: win1,
      bonus: "150FS + 325% БОНУС",
      link: "https://1wauah.xyz/#79x5",
    },
    {
      logo: cat,
      bonus: "200FS + 395% БОНУС",
      link: "https://catchthecatkz.com/d467ed7a5",
    },
    {
      logo: daddy,
      bonus: "150FS + 325% БОНУС",
      link: "https://nice-road-two.com/d74076b1c",
    },
    {
      logo: pinup,
      bonus: "200FS + 395% БОНУС",
      link: "https://onlinepuonline.com/cwbBKAX7/",
    },
    {
      logo: izzi,
      bonus: "500% БОНУС",
      link: "https://izzicasino.life/cf91e09c3",
    },
  ];
  return (
    <div className="cards">
      <h2>
        {text} <span>{mel}</span>
      </h2>
      <div className="cards-cont center">
        {data.map((item, index) => (
          <div className="cards-cont-single" key={index}>
            <div className="cards-cont-single-logo">
              <img src={item.logo} alt="casinoLogo" />
            </div>
            <div className="cards-cont-single-rating">
              <img src={star} alt="star" />
              <p>5.0</p>
            </div>
            <p>{item.bonus}</p>
            <a href={item.link}>
              <button>ИГРАТЬ</button>
            </a>
          </div>
        ))}
      </div>
      <div className="cards-border center"></div>
    </div>
  );
};

export default Cards;
