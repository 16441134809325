import React from "react";
import Cards from "../components/cards/Cards";
import Stream from "../components/stream/Stream";

const StreamsPage = () => {
  return (
    <>
      <Stream />
      <Cards text={""} mel={""} />
    </>
  );
};

export default StreamsPage;
