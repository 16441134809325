import React, { useEffect, useState } from "react";
import "./Footer.scss";

const Footer = () => {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentYear(new Date().getFullYear());
    }, 1000 * 60 * 60); // Update every hour

    return () => clearInterval(intervalId);
  }, []);

  return (
    <footer>
      <div className="footer-container center">
        <p>mellstroytiktok.ru © {currentYear} Официальный веб-сайт Меллстрой</p>
        <p>
          Информация, предоставленная mellstroytiktok official ("Компания",
          "мы", "наши", "нас") о Меллстрой (далее - "Сайт"), предназначена
          исключительно для общего информационного ознакомления. Вся информация
          на Сайте предоставляется добросовестно, но мы не делаем никаких
          заявлений или гарантий какого-либо рода, явных или подразумеваемых,
          относительно точности, адекватности, допустимости, надежности,
          доступности или полноты любой информации на Сайте. В никаком случае мы
          не несем ответственности перед вами за любые убытки или ущерб любого
          рода, возникшие в результате использования сайта или полагания на
          любую предоставленную на сайте информацию. Ваше использование сайта и
          полагание на любую информацию на сайте полностью на ваш собственный
          риск.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
