import React, { useEffect, useState } from "react";
import "./Tiktok.scss";
import mel from "../../assets/melstroyTiktok.png";
import melMobile from "../../assets/melltok2a.svg";

const Tiktok = () => {
  const [relocate, setReloacte] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      setReloacte(window.innerWidth <= 768);
    };

    // Initial check
    handleResize();

    // Event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="tiktok">
      <div className="tiktok-cont center">
        {!relocate ? (
          <>
            <div className="tiktok-cont-left">
              <img src={mel} alt="mel" />
            </div>
            <div className="tiktok-cont-right">
              <h2>
                <span>МЕЛЛСТРОЙ</span> ТИКТОК
              </h2>
              <div>
                <p>📢 Конкурс в TIK-TOK, YouTube Shorts, Insta Reels 2023 📣</p>
                <p>
                  🎚 Каждый может заработать хорошую сумму, загрузив в Тик-Ток,
                  YouTube Shorts, Insta Reels со своего аккаунта видео со
                  стрима, инсты или телеги Мэллстроя.
                </p>
                <p>✔️ Для этого нужно: </p>
                <p>
                  - смонтировать видео про Меллстроя (монтаж свободный, на свой
                  вкус) и загрузить его на видео ресурс, ПО ЖЕЛАНИЮ добавив в
                  описание ссылку на азартную тг (https://t.me/mellstroybonus)
                  или кик канал (kick.com/mellstroy). Если загружаете в YouTube
                  Shorts, то закреп коммента со ссылкой.
                </p>
                <p>
                  ✔️ Набрать 1.000.000 просмотров естественным способом, без
                  использования сервисов накрутки (используйте теги и
                  возможности монтажа);
                </p>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="tiktok-cont-right">
              <h2>
                <span>МЕЛЛСТРОЙ</span> ТИКТОК
              </h2>
              <div>
                <img src={melMobile} alt="mel" />
                <p>📢 Конкурс в TIK-TOK, YouTube Shorts, Insta Reels 2023 📣</p>
                <p>
                  🎚 Каждый может заработать хорошую сумму, загрузив в Тик-Ток,
                  YouTube Shorts, Insta Reels со своего аккаунта видео со
                  стрима, инсты или телеги Мэллстроя.
                </p>
                <p>✔️ Для этого нужно:</p>
                <p>
                  - смонтировать видео про Меллстроя (монтаж свободный, на свой
                  вкус) и загрузить его на видео ресурс, ПО ЖЕЛАНИЮ добавив в
                  описание ссылку на азартную тг (https://t.me/mellstroybonus)
                  или кик канал (kick.com/mellstroy). Если загружаете в YouTube
                  Shorts, то закреп коммента со ссылкой.
                </p>
                <p>
                  ✔️ Набрать 1.000.000 просмотров естественным способом, без
                  использования сервисов накрутки (используйте теги и
                  возможности монтажа);
                </p>
              </div>
            <div className="tiktok-border center"></div>
            </div>
          </>
        )}

        {/* <div className="tiktok-cont-left">
          <img src={mel} alt="mel" />
        </div>

        <div className="tiktok-cont-right">
          <h2>
            <span>МЕЛЛСТРОЙ</span> ТИКТОК
          </h2>
          <div>
            <p>📢 Конкурс в TIK-TOK, YouTube Shorts, Insta Reels 2023 📣</p>
            <p>
              🎚 Каждый может заработать хорошую сумму, загрузив в Тик-Ток,
              YouTube Shorts, Insta Reels со своего аккаунта видео со стрима,
              инсты или телеги Мэллстроя.
            </p>
            <p>✔️ Для этого нужно: -</p>
            <p>
              - смонтировать видео про Меллстроя (монтаж свободный, на свой
              вкус) и загрузить его на видео ресурс, ПО ЖЕЛАНИЮ добавив в
              описание ссылку на азартную тг (https://t.me/mellstroybonus) или
              кик канал (kick.com/mellstroy). Если загружаете в YouTube Shorts,
              то закреп коммента со ссылкой.
            </p>
            <p>
              ✔️ Набрать 1.000.000 просмотров естественным способом, без
              использования сервисов накрутки (используйте теги и возможности
              монтажа);
            </p>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Tiktok;
