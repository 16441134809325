import React, { useEffect, useState } from "react";
import "./More.scss";
import ImgMel from "../../assets/mallstroy-face.png"



const More = () => {

  const [relocate, setReloacte] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setReloacte(window.innerWidth <= 1000);
    };

    // Initial check
    handleResize();

    // Event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);



  return (
    <>
    
    {!relocate ? <>
      <div className="more">
        
      <h2>ПОДРОБНЕЕ</h2>
      <div className="row-info center">
        <div className="info-box">
          <p>
            📢 Стабильные выплаты продолжаются, и на этот раз сумма составила -
            29,700$ 🤑
          </p>
          <br />
          <p>
          ✅ Скриншоты успешных выплат, а также ссылку на таблицу прилагаем
          </p>
          <a href="https://docs.google.com/spreadsheets/d/1YxfQveUZxhKvGywrvUscaFEhwaX5jH-ukXKT0H4zkpo/edit?usp=sharing" target="_blank" rel="noreferrer">
            <button>ТАБЛИЦА</button>
          </a>
        </div>
        <div className="img-box">
        <img src={ImgMel} alt="" />
      </div>
        <div className="info-box">
          <p className="big-text">
          🚀 Участвовать в данном конкурсе может каждый из Вас, также разрешено перезалить другие видео из соц. сетей
          </p>
          <a href="https://docs.google.com/spreadsheets/d/1YxfQveUZxhKvGywrvUscaFEhwaX5jH-ukXKT0H4zkpo/edit?usp=sharing" target="_blank" rel="noreferrer">
            <button>ВЫПЛАТЫ</button>
          </a>
        </div>
      </div>

    </div>
    </> : <></>}
    </>
    
  );
};

export default More;
